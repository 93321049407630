import React, { Fragment } from 'react';
import styled from 'styled-components';
import {Block, BlockStyle} from './Block';
import { ReactComponent as ImagePlaceholderIcon } from '../../Assets/BlockPlaceholderIcons/image.svg';
import { PAGECRAFT_API_URL } from '../../Constants';

const StyledImage = styled.div`
    user-select: none;
    width: 100%;
    ${props=>props.imageFit == 'cover'?'height: 100%;' : null}
    overflow: hidden;
    pointer-events: auto;
    .content {
        object-fit: ${props=>props.imageFit};
        width:100%;
        height: 100%;
    }

    .placeholder {
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        >svg{
            margin: 2px;
            width: 100%;
            height: 100%;
        }
    }
`;


const BlockImageStyle = styled(BlockStyle)`
    display: flex;
    align-items: ${props=>props.verticalAlign}
`;

export class BlockImageProxy extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            url: props.content,
            fileMenuOpened: false
        }
        this.inputRef = React.createRef();
    }
    componentDidMount(){
        this.props.reference.current.style.cssText = this.props.styleText();
    }
    componentDidUpdate(prevProps){
        // if(prevProps.styleText !== this.props.styleText){
            this.props.reference.current.style.cssText = this.props.styleText();
        // }
    }
    handleGetFile(file) {
        const reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onloadend = ((res) => {
            const content = reader.result;
            this.setState({
                ...this.state,
                url: content
            });

        });

        this.uploadImage( file );

    }

    uploadImage(file){
        const formData  = new FormData();
        formData.append("field", JSON.stringify({ id: '5fe2fa7753336419b079ae2f'}));
        formData.append("file", file);

        fetch(`${PAGECRAFT_API_URL}/images`, {
            method: "POST",
            body: formData
        })
        .then(
            r => r.json()
        )
        .then( data => {
            this.setState({
                ...this.state,
                url: data.url
            });
            this.props.setImage(data.url);
        })
    }

    onDrop(event){
        event.preventDefault();
        event.stopPropagation();

        let files = event.dataTransfer.files;
        let file = files[0];

        if (file) {
            this.handleGetFile(file);
        }
    }

    loadFile(e) {
        this.props.setLoadClosed();
        const files = this.inputRef.current.files
        let file = files[0];
        if (file) {
            this.handleGetFile(file);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.openLoadFile !== this.props.openLoadFile && nextProps.openLoadFile) {
            this.inputRef.current.click();
            this.props.setLoadClosed();
        }
    }

    render(){
        return <BlockImageStyle
            id={this.props.id}
            key={this.props.id}
            style={this.props.style}
            width={this.props.width}
            ref={this.props.reference}
            verticalAlign={this.props.verticalAlign}
            className={this.props.className}
            >
                <StyledImage
                    imageFit={this.props.imageFit}
                    onDrop={(e)=>this.onDrop(e)}
                >
                    {this.state.url ?
                        <img
                        src={this.state.url}
                        className="content"
                        alt=''
                        draggable="false"
                        ></img>

                        :
                        <div
                            className="placeholder"
                        >
                            <ImagePlaceholderIcon />
                        </div>
                    }
                </StyledImage>
                {this.props.children}
                <input
                    type="file"
                    style={{display: 'none'}}
                    accept="image/*"
                    ref={this.inputRef}
                    onChange={(e) => this.loadFile(e)}
                />
            </BlockImageStyle>
        }
}

export class BlockImage extends Block{
    constructor(props){
        super(props);
        this.type = 'Image';
        this.openLoad = false;

        let attr = {
            id: 'imageFit',
            displayName: 'Image Fit',
            value: 'contain',
            type: 'AttributeDropdown',
            options: [
                {
                    label: 'Contain',
                    value: 'contain'
                },
                {
                    label: 'Cover',
                    value: 'cover'
                },
                {
                    label: 'None',
                    value: 'none'
                }
            ],
        }
        this.addAttribute( attr );

        attr = {
            id: 'horizontalAlign',
            displayName: 'Image Align',
            value: 'center',
            type: 'AttributeDropdown',
            options: [
                {
                    label: 'Top',
                    value: 'flex-start'
                },
                {
                    label: 'Center',
                    value: 'center'
                },
                {
                    label: 'Bottom',
                    value: 'flex-end'
                }
            ],
        }
        this.addAttribute( attr );
    }

    setImage(img){
        this.image = img;
    }

    pack(){
        let data = super.pack();
        data['src'] = this.image;
        return data;
    }
    unpack(data){
        super.unpack(data);

        let image = data['src'];
        if(image !== undefined){
            this.image = image;
        }

        let imageFit = data['imageFit'];
        if(typeof imageFit === 'string'){
            this.imageFit.value = imageFit;
        }
    }

    setEditing( value) {
        super.setEditing( value );
        if (value) {
            this.openLoad = true;
        }
    }

    handleClose() {
        this.openLoad = false;
    }

    rerollAiText() {
        console.log('ai-reroll-image')
    }

    renderView(){
        return (
            <Fragment key={this.id}>
            { this.isVisible ?
            <>
                {/* { this.isDragging ? this.getPlaceholder()
                : */}
                <BlockImageProxy
                    id={this.id}
                    key={this.id}
                    width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
                    setImage={(v) => this.setImage(v)}
                    reference={this.ref}
                    verticalAlign={this.horizontalAlign.value}
                    styleText={this.style}
                    imageFit={this.imageFit.value}
                    content={this.image}
                    editingHandle={this.editingHandle.bind(this)}
                    openLoadFile={this.openLoad}
                    setLoadClosed={this.handleClose.bind(this)}
                    className={this.className}

                    >
                        {
                            this.children.map((child)=>{
                            return child.renderView();
                            })
                        }
                </BlockImageProxy>
                {/* } */}
            </>
            :
            null
            }
            </Fragment>
        )
    }
}

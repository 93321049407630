import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import View from '../Canvas/View';
import ToolbarPanel from '../Components/Toolbar/ToolbarPanel';
import StoreController from '../StoreController';
import EditorStateContext from './EditorStateContext';
import { rxFetchPages } from '../rx/actions/rxFetchPages';
import {
  rxIsLoading,
  rxError,
  rxShowExitModal,
  eventEmiter,
  rxPageId,
  rxPageSaved,
  rxEditMode,
  rxCustomScripts,
  rxSelectedBlocks,
  rxTitle,
} from '../rx/rxState';

import { useObservable } from '../utils/UseObservable';
import SideMenu from '../Components/SideMenu/SideMenu';
import { fetchSectionsMenu } from '../rx/actions/rxFetchSectionsMenu';
import { fetchProducts } from '../rx/actions/rxFetchProducts';
import ModalExit from '../Components/Modals/ModalExit/ModalExit';
import { isAdmin, getToken, api, username, getUserId } from '../utils/api';
import SectionEditor from '../Components/SectionEditor';
import Spinner from '../Components/Common/Spinner/Spinner';
import { useSearchParams } from 'Hooks/useSearchParams';
import OpenEditorPanel from 'Components/OpenEditorPanel/OpenEditorPanel';
import SignUpPopup from 'Components/Popups/SignUpPopup';
import SaveWebSite from 'Components/Popups/SaveWebSite';
import Login from 'Components/Popups/LogIn';
import { CONSULT_URL, PAGECRAFT_API_URL } from 'Constants';
import { useLocation } from 'react-router';
import s from './PageApp.module.scss';
import RecommendUseDesktopPopup from 'Components/Popups/RecommendUseDesktopPopup';
import { graphQlCall } from '../graphql/utils';
import QUERIES from '../graphql/queries';
import SectionsPopup from 'Components/SectionsPopup/SectionsPopup.jsx';
import { validateToken } from 'Utils';
import MobileSectionsPopup from 'Components/MobileSectionsPopup/MobileSectionsPopup';
import StartVideoBlock from 'Components/StartVideoBlock/StartVideoBlock';

const AppStyle = styled.div`
  height: 100vh;
  background-color: #eaedef;
  position: relative;
  overflow: hidden;

  .main-content {
    display: grid;
    grid-template-columns: max-content auto ${(props) =>
    props.isAdmin ? 'min-content;' : ';'};
    height: calc(100vh - 60px);
  }
`;

const ToolbarStyle = styled.div`
  color: white;
  display: flex;
  height: 60px;
`;

const SidebarStyle = styled.div`
  width: max-content;
  background: black;
`;

const CanvasStyle = styled.div`
  position: relative;
  background-image: url(${process.env.PUBLIC_URL}/assets/bg.png);
`;



const PageApp = (props) => {
  const location = useLocation();
  const [editor, setEditor] = useState({});

  const [signUpModalIsOpen, setSignUpModalIsOpen] = useState(false);
  const [recommendUseDesktopModalIsOpen, setRecommendUseDesktopModalIsOpen] = useState(false);

  const [stripePlanView, setStripePlanView] = useState('withoutInput');
  const queryParams = new URLSearchParams(location.search);
  const [isMobile, setIsMobile] = useState(false);
  const loading = useObservable(rxIsLoading);
  const hasPageSaved = useObservable(rxPageSaved);
  const customScripts = useObservable(rxCustomScripts);
  const title = useObservable(rxTitle);
  const hasUserLoggedIn = !!getToken();

  const error = useObservable(rxError);

  console.log('PROPS:', props)

  useEffect(() => {
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      setIsMobile(true)
    }
  }, []);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);



  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    if (!mediaQuery.matches) return
    setRecommendUseDesktopModalIsOpen(true)
  }, [])

  const {
    query: { generated },
  } = useSearchParams();
  const [savePopUpOpen, setSavePopUpOpen] = useState(false);
  const [loginPopUpOpen, setLoginPopUpOpen] = useState(false);
  const [timeToOpenModalHasPassed, setTimeToOpenModalHasPassed] =
    useState(false);
  const [modalHasBeenClosed, setModalHasBeenClosed] = useState(false);
  const [userId, setUserId] = useState('');
  const [productId, setProductId] = useState('');
  const { setParam } = useSearchParams();

  const setEditorState = (data) => {
    if (data === null) {
      setEditor({ state: null });
      return;
    }
    let colorStyleMap = data.colorStyleMap;
    if (colorStyleMap === undefined) {
      colorStyleMap = editor.colorStyleMap;
    }

    let sizeStyleMap = data.sizeStyleMap;
    if (sizeStyleMap === undefined) {
      sizeStyleMap = editor.sizeStyleMap;
    }

    setEditor({
      state: data.state,
      colorStyleMap,
      sizeStyleMap,
    });
  };

  const handleChangeEditor = (newEditorState) => {
    const oldEditor = { ...editor };
    setEditor({
      ...oldEditor,
      state: newEditorState,
    });
  };

  useEffect(() => {
    if (hasPageSaved) {
      rxIsLoading.next('');
      if (userId && productId) {
        const editUrl = `/edit/edit/${userId}/${productId}/optin`;
        window.open(editUrl, '_self');
      }
      rxPageSaved.next(false);
    }
  }, [hasPageSaved]);

  const saveProject = async () => {
    try {
      rxIsLoading.next(true);
      const token = getToken();
      const owner = username(token);
      const userId = getUserId();
      setUserId(userId);
      const projectId = queryParams.get('projectId');
      if (props.productId == 'new' && !projectId) {
        const projectName = localStorage.getItem('NEW_PROJECT_NAME') || 'First Project';

        const generalProject = await graphQlCall({
          queryTemplateObject: QUERIES.ADD_BLANK_FUNNEL_MUTATION,
          headerType: 'USER-AUTH',
          values: {
            name: projectName,
          }
        });

        const funnel = await graphQlCall({
          queryTemplateObject: QUERIES.CREATE_PAGE_FROM_TEMPLATE_MUTATION,
          headerType: 'USER-AUTH',
          values: {
            name: 'Sales Page',
            enabled: true,
            id: generalProject[0]._id,
          }
        })

        setProductId(funnel._id);
        rxPageId.next(funnel._id);
      } else if (props.productId == 'new' && projectId) {
        const token = await validateToken(getToken());
        const funnels = await fetch(`${PAGECRAFT_API_URL}/pages/funnels-by-project?id=${projectId}`, {
          headers: {
            Authorization: token,
          }
        }).then(res => res.json());
        let pageName;

        if (funnels.length === 0) {
          const projectName = localStorage.getItem('NEW_PROJECT_NAME') || 'First Project';
          await graphQlCall({
            queryTemplateObject: QUERIES.UPDATE_FUNNEL_MUTATION,
            headerType: 'USER-AUTH',
            values: {
              name: projectName,
              id: projectId
            }
          })
          pageName = 'Sales Page';
        } else {
          pageName = localStorage.getItem('NEW_PROJECT_NAME') || 'Sales Page';
        }

        const funnel = await graphQlCall({
          queryTemplateObject: QUERIES.CREATE_PAGE_FROM_TEMPLATE_MUTATION,
          headerType: 'USER-AUTH',
          values: {
            name: pageName,
            enabled: true,
            funnelId: projectId,
          }
        });

        setProductId(funnel._id);
        rxPageId.next(funnel._id);
      } else {
        setProductId(props.productId);
        rxPageId.next(props.productId);
      }

      eventEmiter.next({
        type: 'save',
        payload: {
          modalView: false,
        },
      });

    } catch (err) {
      rxIsLoading.next(false);
      console.error('Save page error', err);
    }
  };
  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.source === window.parent) {
        const data = event.data;

        if (data.message === 'need save') {
          handleSaveEditorPanel()
        }
      }

      if (event.data['target'] === 'view-iframe') {
        const data = event.data['data'];
        if (data) {
          if (data.name === 'selected-blocks') {
            const params = data['params'];
            if (params) {
              // rxSelectedBlocks.next([params])
              // console.log('data:', params['type'], params.id)
            }
          }
        }
      }

    });

    const subscription = rxEditMode.subscribe((isEdit) => {
      if (isEdit) {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const path = window.location.pathname;
        const hash = window.location.hash;
        const search = window.location.search;

        const fullUrl = protocol + "//" + host + path + search + hash;
        window.parent.postMessage({ status: 'saveComplete', url: fullUrl }, CONSULT_URL);

      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const handleSaveEditorPanel = async () => {
    if (hasUserLoggedIn) {
      await saveProject();
    } else {
      setSignUpModalIsOpen(true);
    }
  };

  //fetch Stripe products
  useEffect(() => {
    fetchProducts();
  }, []);

  //fecth sections and pages
  useEffect(() => {
    if (props.productId === 'new') {
      return;
    }
    rxFetchPages({
      username: props.username,
      id: props.productId,
      editMode: props.editMode,
      page: props.page,
      applyBlocks: !generated,
    });
    if (props.editMode) {
      fetchSectionsMenu();
      checkUserAccess();
    }
  }, []);

  const checkUserAccess = async () => {
    const access = await graphQlCall({
      queryTemplateObject: QUERIES.CHECK_SUBSCRIPTION_ACCESS,
      headerType: 'USER-AUTH'
    });
    if (!access.funnels) {
      window.open("/console/login", "__self");
    }
  }

  useEffect(() => {
    if (location.hash.search('signup') >= 0) {
      setSignUpModalIsOpen(true);
      if (location.hash.search('1') >= 0) {
        setStripePlanView('withInput');
      }
      else if (location.hash.search('custom') >= 0) {
        setStripePlanView('custom')
      }
      else {
        setStripePlanView('withoutInput');
      }
    }
    else {
      setSignUpModalIsOpen(false);
    }
  }, [location]);

  let sc = StoreController.instance();
  sc.liveMode = !props.editMode;
  sc.productionMode = props.productMode;

  const handleCloseExitModal = () => {
    if (props.editMode) {
      rxShowExitModal.next(false);
    }
  };

  const handleSwitchToLogin = () => {
    setSignUpModalIsOpen(false);
    setLoginPopUpOpen(true);
  };

  const handleSwitchToSignUo = () => {
    setLoginPopUpOpen(false);
    setSignUpModalIsOpen(true);
  };

  const handleSaveWebSite = () => {
    setSavePopUpOpen(false);
    setSignUpModalIsOpen(true);
  };

  const handleRedirectToHomePage = () => {
    const homeLink = `${CONSULT_URL}/console/login`;
    window.open(homeLink, '_self');
  };

  const handleEditButtonPressed = () => {
    if (hasUserLoggedIn) {
      setParam('generated', undefined);
    } else {
      setSignUpModalIsOpen(true);
    }
  };

  const liveModeRender = () => {
    return (
      //LIVE Mode
      <>
        {generated && (
          <OpenEditorPanel
            onSave={() => handleSaveEditorPanel()}
            onEdit={() => handleEditButtonPressed()}
          />
        )}
        <>
          <SignUpPopup
            open={signUpModalIsOpen}
            onSubmit={(values) => { }}
            onClose={() => {
              setSignUpModalIsOpen(false);
              setModalHasBeenClosed(true);
            }}
            onSwitchToLogIn={() => handleSwitchToLogin()}
            isModal={true}
            versionStripePage={stripePlanView}
          />
          <Login
            onSubmit={() => { }}
            onSwitchToSignUp={() => handleSwitchToSignUo()}
            open={loginPopUpOpen}
            onClose={() => {
              setLoginPopUpOpen(false);
              setModalHasBeenClosed(true);
            }}
          />
        </>
        {customScripts &&
          <div>
            <div dangerouslySetInnerHTML={{ __html: customScripts.funnelHeaderScript }}>
            </div>
            <div dangerouslySetInnerHTML={{ __html: customScripts.headerScript }}>
            </div>
          </div>}
        <View
          id="liveView"
          liveMode={true}
          username={props.username}
          productId={props.productId}
          page={props.page}
          productMode={props.productMode}
        />
        <ModalExit
          liveMode={true}
          productId={props.productId}
          page={props.page}
          onClose={handleCloseExitModal}
        />
        {customScripts &&
          <div>
            <div dangerouslySetInnerHTML={{ __html: customScripts.funnelFooterScript }}>
            </div>
            <div dangerouslySetInnerHTML={{ __html: customScripts.footerScript }}>
            </div>
          </div>}
      </>
    )
  }

  const editorModeRender = () => {
    return (
      //EDITOR Mode
      <AppStyle loading={loading} isAdmin={isAdmin()}>
        <ToolbarStyle>
          <ToolbarPanel
            editorState={editor.state}
            changeEditor={(editorState) =>
              handleChangeEditor(editorState)
            }
            onPublishClicked={() => setSignUpModalIsOpen(true)}
            onHomeClicked={() => setSavePopUpOpen(true)}
          />
        </ToolbarStyle>
        <div className="main-content">
          <SidebarStyle>
            <SideMenu />
          </SidebarStyle>
          {isMobile ? <MobileSectionsPopup /> : <SectionsPopup />}
          <EditorStateContext.Provider
            value={{
              editor: editor,
              setEditorState: setEditorState.bind(this),
            }}
          >
            <CanvasStyle id="view-canvas">
              <View
                id="editorView"
                key="editor-view"
                liveMode={false}
                page={props.page}
                username={props.username}
                productId={props.productId}
                productMode={props.productMode}
                modalView={false}
              />
              <ModalExit
                liveMode={!props.editMode}
                productId={props.productId}
                page={props.page}
                onClose={handleCloseExitModal}
              />
            </CanvasStyle>

            {isAdmin() ? <SectionEditor /> : null}

            {/* <Alerts/> */}
          </EditorStateContext.Provider>
          <SaveWebSite
            onClose={() => setSavePopUpOpen(false)}
            onExit={() => handleRedirectToHomePage()}
            onSave={() => handleSaveWebSite()}
            open={savePopUpOpen}
          />
          <Login
            onSubmit={() => { }}
            onSwitchToSignUp={() => handleSwitchToSignUo()}
            open={loginPopUpOpen}
          />
          <SignUpPopup
            open={signUpModalIsOpen}
            onSubmit={(values) => { }}
            onSwitchToLogIn={() => handleSwitchToLogin()}
          />
          <RecommendUseDesktopPopup
            open={recommendUseDesktopModalIsOpen}
            onClose={() => setRecommendUseDesktopModalIsOpen(false)}
          />
        </div>
      </AppStyle>
    )
  }

  return (
    <div style={{ height: '100%', userSelect: props.editMode ? 'none' : 'auto' }}>
      {props.editMode && <StartVideoBlock />}
      {loading !== '' && (
        <div className={s.loading}>
          <div className={s.spinner}>
            <Spinner size={110} />
          </div>
          <div className={s.message}>
            {loading}
          </div>
        </div>
      )}


      {error && loading === '' ? (
        <div className={s.loading}>
          <h1 className="header error">LOADING DATA ERROR</h1>
        </div>
      ) : (
        <>
          {props.editMode && !generated ? (
            editorModeRender()
          ) : (
            liveModeRender()
          )}
        </>
      )}
    </div>
  );
};

export default PageApp;

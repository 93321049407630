import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useLayoutEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  IBookChapter,
  IBookData,
  IGeneratedChapter,
} from 'Components/books/types';
import { AUTH_USER_TOKEN_KEY } from 'Constants';
import s from './BookGenerator.module.scss';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';

import { SOCKET_URL } from 'Constants';
import { io, Socket } from 'socket.io-client';
import BookEditorView from 'Components/books/BookEditorView';
import { fetchSectionsMenu } from 'rx/actions/rxFetchSectionsMenu';
import { AIBookGenerator } from 'Components/books/bookGeneration';
import {
  rxBlocks,
  rxNewChapterIndex,
  rxChapterIndexForDelete,
  rxSideMenu,
  rxBookDataForSave,
  rxBookNeedSave,
  rxChapterRenameData,
  rxSelectBookImg,
  rxInsertSection,
  rxRemoveSection,
  rxActiveChapterIndex,
} from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import SaveSpinner from 'Components/Common/SaveSpinner/SaveSpinner';
import BookInfoBlockEditor from 'Components/books/BookInfoBlock/BookInfoBlockEditor';
import { getBySocket } from 'utils/socket';

function extractChapterTitle(string: string) {
  const chapterRegex = /^(?:\w+\s+)*Chapter\s+(\d+):?\s+(.+)/;
  const match = string.match(chapterRegex);

  if (match) {
    return match[2];
  } else {
    return string;
  }
}

const BookEditor = () => {
  const { bookId } = useParams<any>();

  const [bookData, setBookData] = useState<IBookData | null>(null);

  const [loadingChapter, setLoadingChapter] = useState(false);
  const [loadingRegenerateChapterData, setLoadingRegenerateChapterData] =
    useState({
      chapterIndex: 0,
      loading: false,
    });
  const [loadingAddChapter, setLoadingAddChapter] = useState(false);

  const chapterPositionForGql = useRef<number[]>([]);

  const [, toggle] = useReducer((p) => !p, true);

  const socket = useRef<Socket | null>(null);
  const indexGenarateChapterText = useRef<number>(0);
  const lastChapter = useRef<IGeneratedChapter | null>(null);
  const [isGeneratedAllBookEnd, setIsGeneratedAllBookEnd] =
    useState<boolean>(true);

  const [
    chapterIndexForStartGenerateByOne,
    setChapterIndexForStartGenerateByOne,
  ] = useState<number | null>(null);

  const [socketStatus, setSocketStatus] = useState('closed');
  const [allChaptersStartGeneration, setAllChaptersStartGeneration] =
    useState(false);

  const blockSections: any = useObservable(rxSideMenu);
  const selectBookImg: any = useObservable(rxSelectBookImg);

  const bookDataForSave: any = useObservable(rxBookDataForSave);
  const [bookSaveLoading, setBookSaveLoading] = useState<boolean>(false);
  const [generatedBookCover, setGeneratedBookCover] = useState<string | null>(
    null
  );
  const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
  const blocks = useObservable(rxBlocks);

  useEffect(() => {
    if (bookDataForSave) {
      saveBook(bookDataForSave);
    }
  }, [bookDataForSave]);

  useLayoutEffect(() => {
    if (selectBookImg) {
      setGeneratedBookCover(selectBookImg);
    }
  }, [selectBookImg]);

  useEffect(() => {
    if (chapterIndexForStartGenerateByOne && bookData) {
      if (chapterIndexForStartGenerateByOne < bookData?.chapters.length) {
        generateChapterText(bookData?._id, chapterIndexForStartGenerateByOne);
      } else {
        setIsGeneratedAllBookEnd(true);
      }
    }
  }, [chapterIndexForStartGenerateByOne]);

  useLayoutEffect(() => {
    if (selectBookImg) {
      setGeneratedBookCover(selectBookImg);
    }
    if (bookId) {
      const getBookDataById = async () => {
        try {
          const bookDataById: IBookData = await graphQlCall({
            queryTemplateObject: queries.GET_BOOK_FOR_EDIT_BY_ID,
            headerType: 'USER-AUTH',
            values: {
              id: bookId,
            },
          });

          if (bookDataById) {
            setBookData({
              _id: bookDataById._id,
              title: bookDataById.title,
              tone: bookDataById.tone,
              chapters: bookDataById.chapters,
              audience: bookDataById.audience,
              structure: bookDataById.structure,
              description: bookDataById.description,
              coverImageUrl: bookDataById.coverImageUrl,
              data: bookDataById.data,
            });
            let imageFromBlocks: null | string = null;
            if (bookDataById.data?.blocks?.length) {
              imageFromBlocks = bookDataById.data.blocks[0].image;
            }
            if (imageFromBlocks) {
              setGeneratedBookCover(imageFromBlocks);
            } else if (bookDataById.coverImageUrl) {
              setGeneratedBookCover(bookDataById.coverImageUrl);
            }
          }
        } catch (_e) {
          const url = `/console/login`;
          window.open(url, "_self");
        }
      };

      getBookDataById();
    }
  }, []);

  useEffect(() => {
    if (socketStatus === 'connected') {
      if (
        blockSections &&
        blockSections.length > 0 &&
        bookData &&
        !allChaptersStartGeneration
      ) {
        if (bookData?.chapters && chapterPositionForGql) {
          setAllChaptersStartGeneration(true);
          let indexChapterForGenerate = 0;
          bookData.chapters.forEach((el: IBookChapter, index) => {
            if (el.text) {
              indexChapterForGenerate = index + 1;
            }
          });
          if (indexChapterForGenerate > 0) {
            handleGenerateBook(indexChapterForGenerate);
          } else {
            handleGenerateBook();
          }
          chapterPositionForGql.current = bookData?.chapters.map(
            (el: IBookChapter, index) => {
              return index;
            }
          );
        }
      }
    }
  }, [socketStatus, blockSections, bookData, allChaptersStartGeneration]);

  useLayoutEffect(() => {
    if (socketStatus === 'closed') {
      socket.current = io(SOCKET_URL);
      socket.current.on('connect', () => {
        console.log('connect');

        setSocketStatus('connected');
      });
    }
  }, []);

  useEffect(() => {
    if (bookData && socket.current) {
      socket.current.on('response-book-all-chapters', (data) => {
        if (!data.chapterText || !data.chapterTitle) {
          return;
        }
        lastChapter.current = {
          bookName: bookData.title,
          chapter: data.chapterText as string,
          title: data.chapterTitle as string,
        };
        onChapterTextEditedDuringStartBookGenerate(data.chapter, data.title);
        toggle();
      });

      socket.current.on('chapter-added', (data) => {
        const newBookData = { ...bookData };

        const newChapterPositionForGql: number[] = [];
        const newChapters = data.chapters.map(
          (el: IBookChapter, index: number) => {
            newChapterPositionForGql.push(index);
            el.title = extractChapterTitle(el.title);

            return el;
          }
        );
        newBookData.chapters = newChapters;
        setBookData(newBookData);

        chapterPositionForGql.current = newChapterPositionForGql;

        setLoadingAddChapter(false);
      });
    }
  }, [bookData, chapterIndexForStartGenerateByOne]);

  useEffect(() => {
    if (lastChapter.current && bookData) {
      if (indexGenarateChapterText.current === bookData.chapters.length) {
        setIsGeneratedAllBookEnd(true);
      }
      const chapterText = lastChapter.current.chapter;
      const chapterTitle = lastChapter.current.title;
      const newBookData = { ...bookData };
      const newChapters = [...newBookData.chapters];
      let changeChapterIndex = 0;
      newChapters.forEach((el, index) => {
        if (el.title === chapterTitle) {
          changeChapterIndex = index;
        }
      });
      newChapters[changeChapterIndex].text = chapterText;
      newBookData.chapters = newChapters;
      indexGenarateChapterText.current = changeChapterIndex + 1;
      setBookData(newBookData);
      addChapterToEditor();
    }
  }, [lastChapter.current]);

  const saveBook = async (dataForSave: any) => {
    if (bookData?._id) {
      setBookSaveLoading(true);
      const coverData = await generateBookCover();
      const values: any = {
        id: bookData?._id,
        data: JSON.stringify(dataForSave),
      };

      if (coverData) {
        values.coverData = coverData;
        const initialImageLink = getCoverImage();
        if (initialImageLink) {
          values.initialImageLink = initialImageLink;
        }
      }

      await graphQlCall({
        queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
        headerType: 'USER-AUTH',
        values,
      });
      setBookSaveLoading(false);
    }
    rxBookNeedSave.next(false);
  };

  const getCoverImage = () => {
    const allBlocks: any = {...blocks as any};
    if (!allBlocks?.blocks?.length) {
      return null;
    }
    const block: any = allBlocks.blocks[0];
    return block.image;
  };

  const generateBookCover = async () => {
    if (!socket.current) {
      console.error('no socket');
      return;
    }
    const image = getCoverImage();
    const coverElement = document.getElementById('1');
    if (!coverElement) {
      console.error('no cover element');
      return;
    }
    coverElement.style.backgroundImage = `url("${image}")`;
    coverElement.style.backgroundSize = 'cover';

    const element = document.getElementById('1');
    const html = element?.outerHTML;
    if (!html) {
      console.error('could not get book cover html');
      return;
    }
    const payload = {
      html,
      width: 800,
      height: 1100,
    };
    const res: any = await getBySocket({
      payload,
      emitEventName: 'create-screenshot',
      resultEventName: 'screenshot-created',
      socket: socket.current,
    });

    return res.stringData;
  };

  const onBookTitleEdited = async (value: string) => {
    if (value.trim().length === 0) {
      return;
    }
    const updateBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookData?._id,
        title: value,
      },
    });
    setBookData(updateBookData);
    rxChapterRenameData.next({ index: 0, newText: value } as any);
  };

  const onChapterTitleEdited = async (value: string, index: number) => {
    if (value.trim().length === 0) {
      return;
    }
    const updateChapterData: IBookChapter = await graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: index,
        title: value,
      },
    });
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[index] = updateChapterData;
      setBookData(newBookData);
      rxChapterRenameData.next({ index: index + 1, newText: value } as any);
    }
  };

  const onChapterRegenerate = async (index: number) => {
    if (!socket.current) {
      return;
    }
    setLoadingRegenerateChapterData({
      chapterIndex: index,
      loading: true,
    });
    const regenerateTitlePayload = {
      bookId,
      token: token,
      requireContent: 'title',
      chapterIndex: index,
    };
    const regenerateTitleRes: any = await getBySocket({
      payload: regenerateTitlePayload,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });

    const regenerateChapterTextPayload = {
      bookId,
      token: token,
      requireContent: 'text',
      chapterIndex: index,
    };
    const regenerateChapterTextRes: any = await getBySocket({
      payload: regenerateChapterTextPayload,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });

    onChapterEdited(
      regenerateTitleRes.text,
      regenerateChapterTextRes.text,
      regenerateTitleRes.chapterIndex
    );

    setLoadingRegenerateChapterData({
      chapterIndex: index,
      loading: false,
    });
  };

  const onChapterEdited = async (
    chapterTitle: string,
    chapterText: string,
    index: number
  ) => {
    if (bookData) {
      const newBookData = { ...bookData };
      const updateChapterData: IBookChapter = {
        title: chapterTitle,
        text: chapterText,
        comments: newBookData.chapters[index].comments,
      };
      newBookData.chapters[index] = updateChapterData;
      const generator = new AIBookGenerator();
      const blockData = generator.createTextBlock(
        chapterTitle,
        chapterText,
        bookData.title
      );
      rxRemoveSection.next(index + 1);
      rxInsertSection.next({
        index: index + 1,
        section: blockData,
      });
      setBookData(newBookData);
      rxActiveChapterIndex.next(index + 1);
      rxBookNeedSave.next(true);
    }
  };

  const onChapterTextEditedDuringStartBookGenerate = (
    value: string,
    titleForSearch: string
  ) => {
    let indexForGql = 0;
    bookData?.chapters.forEach((el: IBookChapter, index) => {
      if (el.title === titleForSearch) {
        indexForGql = index;
      }
    });
    graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: indexForGql,
        text: value,
      },
    });
  };

  const generateChapterText = async (bookId: string, chapterIndex: number) => {
    if (!socket.current) {
      return;
    }
    const payload = {
      bookId,
      token: token,
      chapterIndex,
      requireContent: 'text',
    };
    const res: any = await getBySocket({
      payload,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });
    onChapterTextAddForStartGenerateByOne(res.chapterIndex, res.text);
  };

  const onChapterTextAddForStartGenerateByOne = (
    indexChapter: number,
    textChapter: string
  ) => {
    if (bookData) {
      const newBookData = { ...bookData };
      const newChapters = [...newBookData.chapters];
      newChapters[indexChapter].text = textChapter;
      newBookData.chapters = newChapters;
      setBookData(newBookData);
      if (chapterIndexForStartGenerateByOne) {
        indexGenarateChapterText.current = indexChapter + 1;
        setChapterIndexForStartGenerateByOne(indexChapter + 1);
        addChapterToEditor();
      }
    }
  };
  const onChapterAdd = async (
    indexBefore: number,
    action: 'add' | 'insert'
  ) => {
    if (bookData) {
      setLoadingAddChapter(true);
      await addChapterBySocket(bookData?._id, action, indexBefore);
    }
  };

  const addChapterBySocket = async (
    bookId: string,
    action: string,
    indexBefore: number
  ) => {
    if (!socket.current || !bookData) {
      return;
    }
    let payloadAction = action;
    if (indexBefore == bookData.chapters.length) {
      payloadAction = 'add';
    }
    const payloadAddChapter = {
      bookId,
      token: token,
      positionBefore: indexBefore,
      action: payloadAction,
    };

    const dataAddChapter: any = await getBySocket({
      emitEventName: 'ai-book-add-chapter',
      resultEventName: 'response-book-add-chapter',
      payload: payloadAddChapter,
      socket: socket.current,
    });

    const payloadChapterGenerateText = {
      bookId,
      token: token,
      chapterIndex: indexBefore,
      requireContent: 'text',
    };

    const resChapterGenerateTex: any = await getBySocket({
      payload: payloadChapterGenerateText,
      emitEventName: 'ai-book-re-chapter',
      resultEventName: 'response-book-re-chapter',
      socket: socket.current,
    });
    const newBookData = { ...bookData } as IBookData;

    const newChapterPositionForGql: number[] = [];
    const newChapters: IBookChapter[] = [];
    bookData.chapters.forEach((chapter: IBookChapter, index: number) => {
      if (indexBefore !== undefined && index === indexBefore) {
        newChapters.push({
          comments: [],
          text: resChapterGenerateTex.text,
          title: extractChapterTitle(dataAddChapter.chapterTitle as string),
        });
      }
      newChapters.push(chapter);
    });
    if (payloadAction === 'add') {
      newChapters.push({
        comments: [],
        text: resChapterGenerateTex.text,
        title: extractChapterTitle(dataAddChapter.chapterTitle as string),
      });
    }

    newBookData.chapters = newChapters;
    newBookData.chapters.forEach((el: any, index) => {
      newChapterPositionForGql.push(index);
    });
    setBookData(newBookData);
    chapterPositionForGql.current = newChapterPositionForGql;

    const generator = new AIBookGenerator();
    const blockData = generator.createTextBlock(
      extractChapterTitle(dataAddChapter.chapterTitle as string),
      resChapterGenerateTex.text,
      bookData.title
    );
    rxInsertSection.next({
      index: indexBefore + 1,
      section: blockData,
    });
    setBookData(newBookData);
    rxBookNeedSave.next(true);
    setLoadingAddChapter(false);
  };

  const deleteChapter = async (index: number) => {
    const deleteChapterData = await graphQlCall({
      queryTemplateObject: queries.DELETE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: index,
      },
    });
    if (deleteChapterData.message === 'chapter was deleted') {
      rxChapterIndexForDelete.next(index as any);
      if (bookData) {
        const newBookData = { ...bookData };
        const newChapters = [...newBookData.chapters];
        newChapters.splice(index, 1);
        newBookData.chapters = newChapters;
        setBookData(newBookData);
        const newChapterPositionForGql: number[] = [];
        newBookData.chapters.forEach((el: any, index) => {
          newChapterPositionForGql.push(index);
        });
        chapterPositionForGql.current = newChapterPositionForGql;
      }
    }
  };

  const reorderChapter = (dragIndex: number, dropIndex: number) => {
    if (bookData) {
      const newBookData = { ...bookData };
      let newChapters = [...newBookData.chapters];
      const tempChapter = newChapters.splice(dragIndex, 1);
      newChapters.splice(dropIndex, 0, tempChapter[0]);
      newBookData.chapters = newChapters;
      setBookData(newBookData);
      const newChapterPositionForGql = [...chapterPositionForGql.current];
      const tempChapterIndex = newChapterPositionForGql.splice(dragIndex, 1);
      newChapterPositionForGql.splice(dropIndex, 0, tempChapterIndex[0]);
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const endReorderChapter = async () => {
    const reorderChaptersData = await graphQlCall({
      queryTemplateObject: queries.REORDER_CHAPTERS_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        indexes: chapterPositionForGql.current,
      },
    });
    if (bookData) {
      rxNewChapterIndex.next(chapterPositionForGql.current as any);
      const newBookData = { ...bookData };
      newBookData.chapters = reorderChaptersData;
      setBookData(newBookData);
      const newChapterPositionForGql = newBookData.chapters.map(
        (el: any, index: number) => {
          return index;
        }
      );
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const handleGenerateBook = (indexChapterForGenerate?: number) => {
    let isGeneratedStart = false;
    if (selectBookImg) {
      setGeneratedBookCover(selectBookImg);
    }
    if (
      bookData &&
      bookData.data &&
      bookData.data.blocks &&
      bookData.data.lastId &&
      bookData.data.blocks.length === bookData.chapters.length + 1
    ) {
      const dataForView = {
        blocks: bookData.data.blocks,
        lastId: bookData.data.lastId,
      };
      rxBlocks.next(dataForView as any);
    } else {
      addChapterToEditor();
    }
    let bookChaptersHaveAllText = true;

    bookData?.chapters.map((el: IBookChapter) => {
      if (!el.text) {
        bookChaptersHaveAllText = false;
      }
    });
    if (bookChaptersHaveAllText && bookData?.chapters) {
      indexGenarateChapterText.current = bookData?.chapters.length - 1;
    }

    if (
      socket.current &&
      bookData?.chapters &&
      !bookChaptersHaveAllText &&
      !indexChapterForGenerate
    ) {
      isGeneratedStart = true;
      socket.current.emit('ai-book-all-chapters', {
        chapters: bookData?.chapters,
        bookId: bookData._id,
        token: token,
      });
    }
    if (!bookChaptersHaveAllText) {
      setIsGeneratedAllBookEnd(false);
    }
    if (
      indexChapterForGenerate &&
      !isGeneratedStart &&
      !bookChaptersHaveAllText
    ) {
      indexGenarateChapterText.current = indexChapterForGenerate;
      setChapterIndexForStartGenerateByOne(indexChapterForGenerate);
    }
  };

  useEffect(() => {
    fetchSectionsMenu();
  }, []);

  const addChapterToEditor = async () => {
    if (bookData?.chapters) {
      const newGeneratedChapters = bookData?.chapters.reduce<
        IGeneratedChapter[]
      >((acc, el: IBookChapter, index) => {
        if (el.text) {
          acc.push({
            title: el.title,
            chapter: el.text,
            bookName: bookData.title,
          });
        }
        return acc;
      }, []);

      let result: any = {};
      if (bookData) {
        result = AIBookGenerator.build({
          chapters: newGeneratedChapters,
          coverUrl: generatedBookCover,
          bookTitle: bookData.title,
        });
      } else {
        result = AIBookGenerator.build({
          chapters: newGeneratedChapters,
        });
      }
      rxBlocks.next(result);
    }
  };

  return (
    <div className={s.bookEditorWrapper}>
      <div className={s.bookEditorSideMenu}>
        <BookInfoBlockEditor
          bookTitle={bookData?.title ? bookData?.title : ''}
          onBookTitleEdited={onBookTitleEdited}
          chapters={bookData?.chapters ? bookData?.chapters : []}
          onChapterTitleEdited={onChapterTitleEdited}
          deleteChapter={deleteChapter}
          loadingChapter={loadingChapter}
          onChapterAdd={onChapterAdd}
          reorderChapter={reorderChapter}
          endReorderChapter={endReorderChapter}
          onChapterRegenerate={onChapterRegenerate}
          loadingAddChapter={loadingAddChapter}
          isGeneratedAllBookEnd={isGeneratedAllBookEnd}
          loadingRegenerateChapterData={loadingRegenerateChapterData}
          indexGenarateChapterText={indexGenarateChapterText.current}
        />
      </div>
      <div className={s.bookEditorView}>
        <BookEditorView />
      </div>
      {bookSaveLoading && <SaveSpinner />}
    </div>
  );
};

export default BookEditor;
